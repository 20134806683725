<template>
  <div>
    <div class="table-input">
      <Select v-model="param.violation" clearable placeholder="违规状态">
        <Option :value="0">正常</Option>
        <Option :value="1">违规</Option>
      </Select>
      <Input v-model="param.name" clearable placeholder="标题" />
      <Input v-model="param.nickname" clearable placeholder="商家" />
      <Button type="primary" @click="search()">搜索</Button>
    </div>
    <Table :columns="columns" :data="data" @on-selection-change="changeSelection">
      <template slot-scope="{ row, index }" slot="price">
        <span>{{row.minPrice}}</span>~<span>{{row.maxPrice}}</span>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <span v-if="row.status==0" style="color:#999999">草稿</span>
        <span v-if="row.status==1">发布</span>
      </template>

      <template slot-scope="{ row, index }" slot="violation">
        <span v-if="row.violation==0" style="color:green">正常</span>
        <span v-if="row.violation==1" style="color:red">违规</span>
      </template>

      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" @click="showModalOne(row)">更多</Button>
        <Button type="primary" size="small" @click="showModal(row)" v-if="row.violation==0">违规下架</Button>
        <Button size="small" @click="showModal(row)" v-if="row.violation==1">取消违规</Button>
        <Button type="primary" size="small" @click="setEssence(row)" v-if="row.essence==0" :disabled="row.violation==1||row.status==0">设为精选</Button>
        <Button size="small" @click="setEssence(row)" v-if="row.essence==1">取消精选</Button>
        <Button type="primary" size="small" @click="getQrCode(row)">小程序码</Button>
      </template>
    </Table>
    <Page :total="total" :current="param.page" :page-size="param.rows" show-total show-sizer @on-change="changePage" @on-page-size-change="changePageSize"></Page>

    <!-- 模态框 -->
    <Modal v-model="modal" title="违规下架">
      <div style="margin:10px">ID：{{form.id}}&nbsp;&nbsp;&nbsp;&nbsp;名称：{{form.name}}</div>
      <Form ref="form" :model="form" :rules="formRules" :label-width="100">
        <FormItem label="违规说明：" prop="violationText">
          <Input type="textarea" v-model="form.violationText"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="value=>this.modal=false">取消</Button>
        <Button type="primary" @click="forcedOffShelf()">确定</Button>
      </div>
    </Modal>

    <!-- 模态框 -->
    <!-- <Modal v-model="modalOne" title="更多信息">
      <div style="margin:10px">ID：{{formOne.id}}&nbsp;&nbsp;&nbsp;&nbsp;名称：{{formOne.name}}</div>
      <div style="margin:10px">时间： <span>{{MyUtil.dateToString(formOne.startTime,'yyyy-MM-dd HH:mm')}}</span>至<span>{{MyUtil.dateToString(formOne.endTime,'yyyy-MM-dd HH:mm')}}</span></div>
      <img :src="item" style="width: 100px;height: 100px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in formOne.urlArr" :key="index" @click="MyUtil.showBigImg(item)" />
      <div slot="footer">
        <Button @click="value=>this.modalOne=false">关闭</Button>
      </div>
    </Modal> -->

    <!-- 模态框 -->
    <Modal v-model="modalOne" title="更多信息" width="800">
      <div style="margin:10px">ID：{{formOne.id}}&nbsp;&nbsp;&nbsp;&nbsp;名称：{{formOne.title}}</div>
      <Divider plain orientation="left">票种</Divider>
      <div style="margin:10px" v-if="formOne.goodsActivity">
        <table border="1" style="text-align: center">
          <th width="260px">时段</th>
          <th width="150px">规格</th>
          <th width="100px">售价</th>
          <th width="100px">库存</th>
          <th width="100px">剩余</th>
          <tr v-if="formOne.goodsActivity.listGoodsActivityTicket" v-for="item,index in formOne.goodsActivity.listGoodsActivityTicket" :key="index">
            <td>
              {{MyUtil.dateToString(item.startTime,'yyyy-MM-dd HH:mm')}}至{{MyUtil.dateToString(item.endTime,'yyyy-MM-dd HH:mm')}}
            </td>
            <td>
              {{item.ticket}}
            </td>
            <td>{{item.price}}
            </td>
            <td>
              {{item.stockAll}}
            </td>
            <td>
              {{item.stockCurrent}}
            </td>
          </tr>
        </table>
      </div>
      <Divider plain orientation="left">图片</Divider>
      <img :src="item" style="width: 100px;height: 100px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in formOne.urlArr" :key="index" @click="MyUtil.showBigImg(item)" />
      <div slot="footer">
        <Button @click="value=>this.modalOne=false">关闭</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      MyUtil: this.MyUtil,
      //列表
      deleteIds: [],
      param: {
        name: null,
        page: 1,
        rows: 10,
        violation: 0,
      },
      total: 0,
      data: [],
      columns: [
        {
          type: "selection",
          width: 35,
        },
        {
          title: "ID",
          key: "id",
          width: 100,
        },
        // {
        //   title: "名称",
        //   key: "name",
        //   width: 150,
        //   ellipsis: true,
        //   tooltip: true,
        // },
        {
          title: "标题",
          key: "title",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "话题",
          key: "topic",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "描述",
          key: "text",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家名称",
          key: "nickname",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家电话",
          key: "phone",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "地标",
          key: "landmark",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "地址",
          key: "address",
          width: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "价格",
          slot: "price",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "发布状态",
          slot: "status",
          width: 120,
        },
        {
          title: "违规状态",
          slot: "violation",
          width: 150,
        },
        {
          title: "违规原因",
          key: "violationText",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: 320,
        },
      ],
      //违规下架
      modal: false,
      form: {
        id: null,
        violationText: null,
      },
      formRules: {
        // violationText: [
        //   {
        //     required: true,
        //     message: "此项不能为空",
        //     trigger: "blur",
        //   },
        // ],
      },
      //更多信息
      modalOne: false,
      formOne: {},
    };
  },
  methods: {
    getDataList() {
      this.MyUtil.ajax()
        .post("/GoodsController/getGoodsActivityListForWeb", this.param)
        .then((resData) => {
          this.data = resData.data;
          this.total = resData.total;
          if (this.data) {
            this.data.forEach((element) => {
              if (element.headUrl) {
                element.headUrlArr = element.headUrl.split(",");
              }
            });
          }
        });
    },
    changePage(page) {
      this.param.page = page;
      this.getDataList();
    },
    changePageSize(rows) {
      this.param.rows = rows;
      this.getDataList();
    },
    changeSelection(selection) {
      this.deleteIds = [];
      selection.forEach((element) => {
        this.deleteIds.push(element.id);
      });
    },
    search() {
      this.param.page = 1;
      this.getDataList();
    },

    showModal(row) {
      this.form.id = row.id;
      this.form.name = row.name;
      this.modal = true;
    },

    forcedOffShelf() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.MyUtil.ajax()
            .post("/GoodsController/forcedOffShelf", this.form)
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
              this.modal = false;
            });
        }
      });
    },

    showModalOne(row) {
      this.formOne = {};
      this.MyUtil.ajax()
        .post("/GoodsController/getGoodsActivityUpdateInfo", { id: row.id })
        .then((resData) => {
          this.formOne = resData;
          if (this.formOne && this.formOne.maxUrl) {
            this.formOne.urlArr = this.formOne.maxUrl.split(";");
          }
          this.modalOne = true;
        });
    },

    setEssence(row) {
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/GoodsController/setEssence", { id: row.id })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
            });
        },
      });
    },

    getQrCode(row) {
      const msg = this.$Message.loading({
        content: "正在生成小程序码",
        duration: 10000,
      });
      this.MyUtil.ajax()
        .post("/GoodsController/getGoodsQrCode", { id: row.id })
        .then((resData) => {
          var a = document.createElement("a");
          a.href = resData;
          a.click();
          setTimeout(msg, 100);
        });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>
